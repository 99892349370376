// press 
.page-feature img {
    mix-blend-mode: normal;
}

// about


// header mobile 375

.page-wrapper {
    position: relative;
}

.about-welcome-text {
    max-width: 23em;
}

.about-welcome-text h3 {
    text-transform: none;
}

.about-container {
    position: relative;
    padding-top: 16em;
    display: none;
}

.section-about {
    position: relative;
    padding-bottom: 30em;
}

.section-about:last-child {
    padding-bottom: 17em;
}

.about-header {
    padding-bottom: .7em;
    position: absolute;
    border-bottom: 1px solid $border-color;

    right: 0;
    width: 92%;
}

.about-body {
    position: absolute;
    max-width: 80%;
    padding-top: 3.4em;
    right: 12%;
}

.about-image {
    width: 35%;
    position: absolute;
    transform: translateY(-92%);
    right: 2em;
}

// tablet small

@media screen and (min-width: 500px) {
    .about-welcome-image {
        width: 70%;
    }

    .section-about:first-child {
        padding-top: 8em;
    }

    .about-image {
        transform: translateY(-95%);
    }

    .about-body {
        max-width: 53%;
        right: 39%;
        padding-top: 3em;
    }

}


// header tablet 768

@media screen and (min-width: 768px) {

    .about-welcome-image {
        width: 30%;
    }

    .about-welcome-text {
        padding-top: 0;
        margin: auto auto;

            h3 {
                text-transform: none;
                line-height: 1.35em;
            }
    }

    .about-container {
        padding-top: 10em;
    }

//about right 

    .about-header-right {
        right: 0;
        width: 80%;
    }

    .about-body-right {
        right: 54%;
    }

    .about-image-right {
        right: 20%;
    }

// about left 

    .about-header-left {
        left: 0;
        width: 69%;
        text-align: end;
    }

    .about-body-left {
        left: 57.5%;
    }

    .about-image-left {
        left: 20%;
    }

// global about 


    .about-body {
        width: 80%;
        max-width: 26%;
        padding-top: 2.8em;
    }

    .about-image {
        width: 20%;
        transform: translateY(-20%);
    }


  }

  // header laptop 1280 

  @media screen and (min-width: 1280px) {

    .section-about {
        height: 30em;
    }

    .about-body {
        padding-top: 3.6em;
    }

    .about-body-left {
        left: 60%;
    }

  }

// desktop

@media screen and (min-width: 1800px) {

    .about-welcome-text {
        max-width: 30em;
    }

    .section-about {
        padding-bottom: 40em;
    }

    .about-body {
        padding-top: 4em;
    }

    .about-body-left {
        left: 61.7%;
    }
}





