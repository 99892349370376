/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class],
li[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Deafult list */
ul {
  margin: 0;
  padding: 0;
  height: 0;
}

ol {
  padding: 0;
}

/* Deafult button styling */
button {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
}

/* input styling */
 input {
  background-image: none;
  background-color: var(--background) ;
  border: 1px solid #9D9D9D;
  color: inherit;
  font: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: background-color 0.3s;
  width: 104%;
  transform: translateX(-0.44em);
 }

 .form input[type="name"],
 .form input[type="email"],
 .form input[type="message"],
 .form input[type="submit"] {
  inline-size: 100%;
}

input[type="text"]:focus,
input[type="text"]:hover,

input[type="email"]:focus,
input[type="email"]:hover {
 background-color: #d8d8d8;
}

textarea {
  background-color: var(--background) ;
  border: 1px solid #9D9D9D;
}

input, textarea {
  padding:.3em .4em;
}

