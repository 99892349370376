// project


// header mobile 375

.project-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.project-left-container {
    display: grid;
    width: 100%;
    height: 100vh;
}

.project-close {
    position: absolute;
    top: 1.3em;
    left: 1.5em;
    z-index: 1000;
    transition: transform .3s ease-out;
}

.project-close:hover {
    transform: rotate(90deg);
}

.project-logo {
    // font-size: clamp(0.7rem, 1.3vw, 1.5rem);
    // letter-spacing: .7rem;
    text-align: center;
    padding: 5em 2em 0 2em;
    opacity: 0;
}

.gallery-left-wrapper {
    align-self: center;
}

/* gallery left images  */

.gallery-left-images {
    position: relative;
    width: 100%;
}

.images img {
    display: none;
}

.images img.active {
    display: block;
}

.project-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
}

.gallery-left img, .project-info {
    width: 80%;
    margin: 0 auto;
}

/* project title */

.project-data-wrapper {
    width: 100%;
    padding-bottom: 2em;
    align-self: end;
}

.project-data-single {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $gutter-mobile;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

.project-data-left {
    display: flex;
}

.project-title-single {
    transition: transform .3s ease-in-out;
}

.project-title-single:hover {
    transform: translateX(1em);
}

.project-number {
    transform: translateY(0px);
}

.project-next {
    align-self: flex-end;
    cursor: pointer;
    transition: transform .3s ease-in-out;
    user-select: none;
}

.project-next:hover {
    transform: translateX(1em);
}

/* right gallery */

.project-right-container {
    display: none;
}

.gallery-right img:hover {
    mix-blend-mode: luminosity;
}


@media screen and (min-width: 500px) {
    .gallery-left-wrapper {
        padding: $gutter-mobile;
    }
}

// tablet 768

@media screen and (min-width: 768px) {

    .project-logo {
        padding: 4em 2em 0 2em;
    }

    .project-container {
        display: grid;
        grid-template-columns: 1.2fr 1fr;
    }

    .gallery-left img, .project-info {
        width: 48%;
        margin: 0 auto;
    }
    
    .project-data-wrapper {
        position: relative;
        width: 100%;
    }

    .project-data-single {
        padding: 0 5em;
    }

    .project-number {
        margin: auto 0;
    }


    /* right gallery */

    .project-right-container {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .gallery-right > img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-size: cover;
        background-position: 50%;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

  }

  // laptop 1280 

  @media screen and (min-width: 1280px) {
    .svg-logo {
        width: 400px;    
    }
    
  }





