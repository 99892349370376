// typeface

@font-face {
    font-family: "gt-zirkon";
    src: url("GT-Zirkon-Thin.woff2") format("woff2"),
         url("GT-Zirkon-Thin.woff") format("woff");
    font-weight: thin;
    // font-display: swap;
  }


// typography

h1, h2, h3, h4 {
    // color: $type-color;
    font-family: "gt-zirkon";
    text-transform: uppercase;
    font-weight: 100;
}

h1 {
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: calc(max(2.1vw, 1.2rem));
}


h2 {
    font-size: clamp(1.2rem, 3vw, 2rem);
}

.project-title-single h2 {
    font-size: clamp(1.1rem, 2vw, 2rem);
}

h3 {
    font-size: clamp(.8rem, 1vw, 1.5rem);
    line-height: 1rem;
}

h4 {
    font-size: 1.2rem;
    // font-size: clamp(.5rem, 1vw, 1rem);
    // line-height: 1rem;
}

p {
    color: var(--foreground);
    font-family: "gt-zirkon";
    font-size: .75rem;
}

a {
    color: var(--foreground);
    text-decoration: none;
}

a:hover {
    color: $hover-color;
}

ol {
    color: var(--foreground);
    font-family: "gt-zirkon";
}

.project-number {
    color: var(--foreground);
    font-family: "gt-zirkon";
}


