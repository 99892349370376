// variable 


// color

:root {
    --base-color: #EDE8DE;
    --type-color: #1C1C1E;
    --button-color: #BEBEBE;
    --svg-color: none;

    --foreground: var(--type-color);
    --background: var(--base-color);
}

.darkmode {
    --base-color: #b4b3b2;
    --type-color: #1C1C1E;
    --button-color: #969696;
    --svg-color: invert(85%) sepia(8%) saturate(45%) hue-rotate(349deg) brightness(85%) contrast(86%);

    --foreground: var(--base-color);
    --background: var(--type-color);
}

$base-color: #EDE8DE;
$type-color: #1C1C1E;
$hover-color: #666565;
$border-color: #9D9D9D;
$dark-mode: #b4b3b2;




// layout 
$gutter-mobile: 2em;
$gutter: 3em; 
$gutter-desktop: 3em 5em 3em 5em;


