// header


// header mobile 375

.header, .page-header {
    padding: $gutter-mobile;
}

.header {
    padding-bottom: 0;
}

.logo, .page-logo {
    padding-bottom: 1em;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: .7rem;
    font-size: clamp(0.7rem, 1.3vw, 1.5rem);
    white-space: nowrap;
}

.page-logo a:hover {
    color: var(--typecolor);
}

.header-info, .page-info {
    width: 230px;
}

// header tablet 768

@media screen and (min-width: 768px) {
    .header, .page-header {
        display: flex;
        justify-content: space-between;
        text-align: right;
        padding: $gutter;
      }

      .logo, .page-logo {
          letter-spacing: 1rem;
      }

  }

  // header laptop 1280 

  @media screen and (min-width: 1280px) {
    .svg-logo {
        width: 400px;    
    }

    .header-info, .page-info {
        width: 300px;
    }
  }





