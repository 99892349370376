// index


// index mobile 375

/* landing animation */

.landing-container {
    width: 100%;
    position: absolute;
    top: 0;
}

.landing-container {
        align-items: center;
        background: var(--base-color);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        transition: opacity 1s linear;
        width: 100%;
        z-index: 9999;
        pointer-events: none;
}

// .landing-container.loaded{
//     display: none;
//   }
  


.landing-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/*---coming soon---*/

.project-title:hover h2 a {
    display: none;
}

.title-0:hover h2 a, .title-2:hover h2 a, .title-4:hover h2 a, .title-6:hover h2 a, .title-14:hover h2 a, .title-10:hover h2 a, .title-12:hover h2 a {
    display: block;
}

.project-title:hover span h2:before {
    content:"Coming Soon"; 
}

.title-0:hover span h2:before, .title-2:hover span h2:before, .title-4:hover span h2:before, .title-6:hover span h2:before, .title-14:hover span h2:before,  .title-10:hover span h2:before, .title-12:hover span h2:before  {
    content: none;
}

.project-title {
    width: 100%;
}


/*-----welcome-----*/

.welcome-container, .about-welcome-container {
    padding: $gutter-mobile;
}

.welcome-gallery, .about-welcome-image {
    position: relative;
    width: 100%;
}

.welcome-gallery img {
    display: none;
}

.welcome-gallery img.active {
    animation-name: galleryFade, activeFadeOut;
    animation-duration: 2.5s, .3s;
    animation-timing-function: ease-out;
    animation-delay: 0s, 4.7s;
    animation-direction: alternate;
    display: block;
}

@keyframes galleryFade {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  @keyframes activeFadeOut {
      from {
          opacity: 1;
      }

      to {
          opacity: 0;
      }
  }

 .slider-nav {
      display: none;
  }

  .slider-nav ul {
      display: flex;
      justify-content: space-between;
  }

  button {
    filter: var(--svg-color);
  }

  button.previous {
    transform: rotate(180deg) 
               translateY(5px);
  }

  .welcome-bio, .about-welcome-text {
    padding-top: 2em;
  }

 

/*-----end welcome-----*/

/*-----projects-----*/

.project-nav-container {
    padding-top: 8em;
    padding-bottom: 12.4em;
}

.page-navigation {
    padding-top: 16.9em;
}

.project-nav-container ol {
    list-style-type: decimal-leading-zero;
}

.project-nav-container ol li {
    padding: .5em 0 .5em 2em;
    border-top: 1px solid $border-color;
}

.project-nav-container ol li:last-child {
    border-bottom: 1px solid $border-color;
}


li.project-data, li.page-data {
    display: flex;
}

.project-number {
    padding-right: 1em;
    align-self: flex-end;
}

.project-feature {
    opacity: 0;
    position: absolute;
    transform: translateY(-50%);
    width: 30%;
    right: $gutter;
    transition: opacity .55s ease-in-out;
    background: $base-color;
}

.page-feature {
    width: 25%;
}

.item-9, .item-19, .item-21, .item-23, .item-25, .item-27, .item-29, .item-17  {
    filter: blur(1rem);
}

  .project-title:hover + .project-feature,   .page-title:hover + .project-feature {
      opacity: 1;
      transition: opacity .45s ease-in-out;
  }

/*-----end projects-----*/

/*-----footer-----*/


.footer-container {
    padding-top: 2em;
    padding: $gutter-mobile;
}

.footer-links {
    padding-bottom: 1em;
}

.footer-links.contact {
    cursor: pointer;
}

//about

.section {
    padding-bottom: 2em;
}



//osd logo

.osd-logo-container {
    padding: $gutter-mobile;
}

.osd-logo {
    display: flex;
    justify-content: center;
}

.osd-logo svg {
    width: 50px;
    animation: logoRotate 3s linear infinite;
    filter: var(--svg-color);
}
.header-logo svg {
    display: none;
}

.osd-logo svg:hover {
    cursor: pointer;
}

@keyframes logoRotate {  
    0% { 
        transform: rotate(0deg); 
    }
    
    100% {
        transform: rotate(360deg); 
    }
}

//links

.links-container {
    text-align: center;
    padding-top: 2em;
}


// form modal 

.content-wrapper {
    position: relative;
}

.form-wrapper {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.form-modal {
    margin: 0 auto;
    position: fixed;
    z-index: 3000;
    background: var(--background);
    color: var(--foreground);
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 5em;
    box-shadow: 7px 3px 20px rgba(0,0,0,0.1);
}

.form-close {
    position: absolute;
    top: 1em;
    left: 1.5em;
    cursor: pointer;
    transition: transform .3s ease-out;
}

.form-close:hover {
    transform: rotate(90deg);
}

.overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

form h4 {
    padding-bottom: .3em;
}

button#form-submit {
    color: var(--foreground);
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
    border: 1px solid var(--foreground);
    padding: .2em .5em;
    width: 11.1em;
  }

  button#form-submit:hover {
    background-color: var(--foreground);
    transition: background-color 0.3s;
    color: var(--background);
  }

  .footer-links {
      position: relative;
  }

  .svg-oval {
    position: absolute;
    top: -26px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    filter: var(--svg-color);
  }

svg ellipse {
      stroke-dasharray: 450;
      stroke-dashoffset: 450;
  }

.contact:hover {
    color: $hover-color;
 }

/*-----end footer-----*/


// index tablet 768

@media screen and (min-width: 768px) {

    .header-info h3 {
        line-height: 1.1rem;
    }
    
    .welcome-container, .about-welcome-container {
        display: flex;
    }
    
    .welcome-gallery {
        width: 30%;
    }

    .welcome-container, .about-welcome-container {
        padding: $gutter;
    }

    .header-logo svg {
        // animation: logoRotate 3s linear infinite;
        filter: var(--svg-color);
        width: 50px;
        height: 100%;
        padding-top: .2em;

        display: unset;
    }
    
    .welcome-bio {
        // align-self: center;
        // margin: 0 auto;
       align-self: flex-end;
       padding-left: 2em;
    }

    .slider-nav {
       display: block;
       position: absolute;
       padding-top: .3em;
       width: 100%;
    }

    .project-data {
        position: relative;
        z-index: 1000;
    }

    .project-number {
        transform: translateY(-7px);
    }

    .project-feature img {
        z-index: 1;
        display: block;
        // mix-blend-mode: luminosity;
}

    .footer-container {
        padding-top: 20em;
        z-index: 2000;
        padding: $gutter;
    }

    // .about-container {
    //     display: grid;
    //     grid-template-columns: repeat(3,1fr);
    //     grid-column-gap: 8em;
    //     padding-top: 20em;
    // }

    .osd-logo {
        padding: 2em 0 2em 0;
    }

    .links-container {
        padding-top: 0;
    }

    .links-top {
        display: flex;
        justify-content: space-between;
    }

    .links-bottom {
        display: flex;
        justify-content: space-between;
    }

    .footer-links.contact {
        transform: translateX(-0.7em);
    }

    .footer-links {
       padding-bottom: 0;
    }

    .form-modal {
        width: 40%;
    }

  }

  // index laptop 1280 

  @media screen and (min-width: 1280px) {
      .header, .welcome-container, .footer-container, .page-header, .about-welcome-container {
          padding: $gutter-desktop;
      }

      .project-nav-container ol li {
        padding-left: 5em;
      }

      .welcome-bio h1 {
            line-height: 1.7rem;
      }

      .header-info h3 {
          line-height: 1rem;
      }
  }




