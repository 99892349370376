// animations


// index mobile 375

/*-----welcome-----*/

// logo 
// .logo {
//     animation: logoSlide 1s ease-in;
// }

// @keyframes logoSlide {
//     from {
//       opacity: 0;
//     }
  
//     to {
//         opacity: 100%;
//     }
// }

// tag line

// .header-info {
//     opacity: 0;
//     animation: infoSlide 1s ease-out .5s;
//     animation-fill-mode: forwards;

// }

// @keyframes infoSlide {
//     from {
//       transform: translateX(2em);
//       opacity: 0;
//     }
  
//     to {
//         transform: translateX(0);
//         opacity: 100%;
//     }
// }

// welcome images

// .welcome-gallery {
//     opacity: 0;
//     animation: gallerySlide 1s ease-out 1.3s;
//     animation-fill-mode: forwards;
// }

// @keyframes gallerySlide {
//     from {
//       transform: translateX(-2em);
//       opacity: 0;
//     }
  
//     to {
//         transform: translateX(0);
//         opacity: 100%;
//     }
// }

// welcome bio

// .welcome-bio {
//     opacity: 0;
//     animation: bioSlide 1s ease-out 1.5s;
//     animation-fill-mode: forwards;
// }

// @keyframes bioSlide {
//     from {
//       transform: translateY(2em);
//       opacity: 0;
//     }
  
//     to {
//         transform: translateY(0);
//         opacity: 100%;
//     }
// }

/*-----end welcome-----*/


/*-----projects-----*/

// project nav

.project-title, .page-title  {
    transition: transform .3s ease-in-out;
}

.project-title:hover, .page-title:hover {
    transform: translateX(1em);
}

.project-data {
    opacity: 0;
}

.nav-fade {
    animation: navFade 1s ease-out;
    animation-fill-mode: forwards;
}

@keyframes navFade {
    from {
      transform: translateY(2em);
      opacity: 0;
    }
  
    to {
        transform: translateY(0);
        opacity: 100%;
    }
}


// about section 


// .about-image {
//     animation: aboutImage 1s ease-out 1s;
//     animation-fill-mode: forwards;
//     opacity: 1;
// }

// @keyframes aboutImage {
//     from {
//       transform: translateY(3em);
//       opacity: 0;
//     }
// }


/*-----end projects-----*/


/*-----footer-----*/

/*-----end footer-----*/


// index tablet 768

@media screen and (min-width: 768px) {
    

  }

  // index laptop 1280 

  @media screen and (min-width: 1280px) {
   
  }




