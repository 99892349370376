@import '_reset';
@import '_variable';
@import '_typography';
@import '_header';
@import '_index';
@import '_project';
@import '_animations';
@import '_about';


body {
	margin: 0;
	background: var(--background);
	color: var(--foreground);
	transition: background .5s ease-in-out;
}




